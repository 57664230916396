module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../../../node_modules/@yumgmbh/gatsby-theme-yum-components/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#fff","theme_color":"#21272A","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8d724e12dba6073b73debea19962589c"},
    },{
      plugin: require('../../../node_modules/@yumgmbh/gatsby-plugin-sb-nav/gatsby-browser.js'),
      options: {"plugins":[],"storyblokToken":"qQMii0T1HMMC4Jmw3IrKVwtt","version":"published","useFolderAsLanguage":false,"useFolderAsCountryAndLanguage":true,"globalPathCountryAndLanguageDelimiter":"-","storyblokFolderWhitelist":["de-de","gb-en","fr-fr"],"by_slugs":"global/navigations/*"},
    },{
      plugin: require('../../../themes/gatsby-theme-rothenberger/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
